import { render, staticRenderFns } from "./TestPositionnementPage.vue?vue&type=template&id=2b2d8557&"
import script from "./TestPositionnementPage.vue?vue&type=script&lang=js&"
export * from "./TestPositionnementPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports