<template>
	<div class="ortho-results-container">
		<div>
			<v-card
				class="mx-auto shadow-blue"
				max-width="600"
				:class="{
					pa7: $vuetify.breakpoint.smAndDown,
					pa60: $vuetify.breakpoint.mdAndUp,
				}"
			>
				<div class="mb20">
					<img src="@/assets/icons/check.svg" class="mt20 check-icon-results" />
				</div>
				<div class="mb20">
					<div class="ortho-results-score-title ortho-bold">
						<span>Ton parcours a été <br />personnalisé avec succès</span>
					</div>
				</div>
				<div class="mb20 mt20">
					<div class="ortho-results-text mb20 pr-10 pl-10">
						<span class="ortho-bold ortho-text-green">Félicitations</span>, ta première session est prête !
					</div>
				</div>
				<v-btn color="black" dark large :loading="loading" class="hvr-shrink mt20 mb20" @click="toPositionTestReport">
					Mon rapport personnel
					<v-icon class="ortho-icon-white fs15 ml5">fas fa-arrow-right</v-icon>
				</v-btn>
			</v-card>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FinishPositionTestAlert',
	props: {
		testScoreId: String,
	},
	data() {
		return { loading: true };
	},
	mounted: function () {
		setTimeout(() => {
			this.loading = false;
		}, 5000);
	},
	methods: {
		toPositionTestReport() {
			this.$router.push({
				name: 'PositionTestResultPage',
				params: { testScoreId: this.testScoreId },
				query: { formationId: this.$route.params.idFormation },
			});
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('testPositionnement-goToResultsPage');
			}
		},
	},
};
</script>
