<template>
	<div v-if="!error" class="h-100">
		<Loading :show="loading" label="3, 2, 1..." />
		<InfoBeforeStarting
			v-if="step === 'info'"
			@clicked="beginPositionTest"
			:userFirstName="userFirstName"
			:test="test"
		/>

		<InTest
			v-else-if="step === 'questionnaire'"
			:testBank="testBank"
			:testTracks="testTracks"
			:onCompleteTest="finishPositionTest"
		>
			<template v-slot:finished-test-modal>
				<FinishPositionTestAlert :testScoreId="testScores[0]._id" />
			</template>
		</InTest>
	</div>
	<Error v-else :error="error" />
</template>

<script>
import axios from 'axios';
import Loading from 'vue-full-loading';

import InfoBeforeStarting from './InfoBeforeStarting';
import Error from '../../views/layouts/Error';
import InTest from '@/components/in-test/InTest.vue';
import FinishPositionTestAlert from './FinishPositionTestAlert.vue';
import { sortObjArr } from '@/helpers/array.js';

export default {
	data() {
		return {
			loading: false,
			step: '',
			userFirstName: '',
			test: null,
			testBank: null,
			testScores: null, // Test score of all attempts. This is position test should have only 1 test score
			testTracks: null,
			trial: '', // Formation trial
			error: false,
		};
	},
	name: 'TestPositionnement',
	components: {
		InfoBeforeStarting,
		Error,
		Loading,
		InTest,
		FinishPositionTestAlert,
	},
	async created() {
		try {
			const { user, formation } = await this.fetchUserAndFormation();

			if (formation.score_test != null) {
				this.$router.push({ name: 'Dashboard' });
				return;
			}

			await Promise.all([
				this.fetchAndSetTest(formation.position_test_id, user._id),
				this.fetchAndSetTestScore(formation.position_test_id, user._id),
			]);

			this.userFirstName = user.first_name;
			this.trial = formation.test_trial;

			if (this.testScores && this.testScores[0] && this.testScores[0].status === 'completed') {
				this.$router.push({
					name: 'PositionTestResultPage',
					params: { testScoreId: this.testScores[0]._id },
					query: { formationId: formation._id },
				});
			} else {
				if (this.$route.meta.from) await this.beginPositionTest('startTest');
				else this.step = 'info';
			}
		} catch (err) {
			this.error = true;
		}
		if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('testPositionnement-start');
	},
	methods: {
		async beginPositionTest(value) {
			if (value !== 'startTest') return;
			this.loading = true;

			await this.fetchAndSetTestTracks(this.test.test_bank_id);

			if (this.testScores == null || this.testScores.length === 0) {
				this.testScores = [
					await this.createTestScore(this.test._id, this.$route.params.idUser, this.test.test_bank_id),
				];
			} else if (this.testScores && this.testScores[0] && this.testScores[0].status !== 'completed') {
				// If testScore status !== completed => delete and create new test score because 1 position test should only have 1 test score
				await this.deleteTestScore(this.testScores[0]._id);
				this.testScores = [
					await this.createTestScore(this.test._id, this.$route.params.idUser, this.test.test_bank_id),
				];
			}

			this.step = 'questionnaire';
			this.loading = false;
		},
		async fetchUserAndFormation() {
			const res = await axios.get('/api/users/' + this.$route.params.idUser);
			return { user: res.data.user, formation: res.data.formation };
		},
		async fetchAndSetTest(testId, userId) {
			const { test, testBank } = await this.$store.dispatch('tests/fetchAndSetTest', { testId, userId });
			this.test = test;
			this.testBank = testBank;
		},
		async fetchAndSetTestScore(testId, userId) {
			this.testScores = await this.$store.dispatch('testScores/fetchTestScores', { testId, userId });
		},
		async createTestScore(testId, userId, testBankId) {
			const testScore = await this.$store.dispatch('testScores/createTestScore', {
				testId: testId,
				userId: userId,
				testBankId: testBankId,
				attempt: 1,
			});
			return testScore;
		},
		async fetchAndSetTestTracks(testBankId) {
			const _testTracks = await this.$store.dispatch('tests/fetchAndSetTestTracks', { testBankId });
			this.testTracks = sortObjArr(_testTracks, 'ASC', 'position', 'number');
		},
		async deleteTestScore(testScoreId) {
			await axios.delete('/api/testScores/' + testScoreId);
		},
		async finishPositionTest() {
			await axios.post('/api/tests/finish-position-test', {
				formationId: this.$route.params.idFormation,
				testScoreId: this.testScores[0]._id,
			});
		},
	},
	beforeDestroy() {
		this.$store.dispatch('tests/clear');
		this.$store.dispatch('testScores/clear');
	},
};
</script>
